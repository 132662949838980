import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ad36a77 = () => interopDefault(import('../pages/applypass/index.vue' /* webpackChunkName: "pages/applypass/index" */))
const _40c986c3 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _188bd009 = () => interopDefault(import('../pages/banjo.vue' /* webpackChunkName: "pages/banjo" */))
const _19044453 = () => interopDefault(import('../pages/banjo-home.vue' /* webpackChunkName: "pages/banjo-home" */))
const _91b0cd2e = () => interopDefault(import('../pages/browser-update.vue' /* webpackChunkName: "pages/browser-update" */))
const _6cfd1b1e = () => interopDefault(import('../pages/company-article/index.vue' /* webpackChunkName: "pages/company-article/index" */))
const _8a21f102 = () => interopDefault(import('../pages/company-search-results/index.vue' /* webpackChunkName: "pages/company-search-results/index" */))
const _99635af2 = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _d0cef306 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _6740afba = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _631fab0c = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _97b0170a = () => interopDefault(import('../pages/job-match-feedback/index.vue' /* webpackChunkName: "pages/job-match-feedback/index" */))
const _456ea798 = () => interopDefault(import('../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _9b42df70 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _4b97080f = () => interopDefault(import('../pages/my-searches/index.vue' /* webpackChunkName: "pages/my-searches/index" */))
const _463d0280 = () => interopDefault(import('../pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _783734ca = () => interopDefault(import('../pages/one-auth.vue' /* webpackChunkName: "pages/one-auth" */))
const _bb5cfb44 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _cbfb355a = () => interopDefault(import('../pages/reset-email.vue' /* webpackChunkName: "pages/reset-email" */))
const _6bd50a34 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _d2b442d2 = () => interopDefault(import('../pages/resume-writing.vue' /* webpackChunkName: "pages/resume-writing" */))
const _5b8d0bfa = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _447ed72f = () => interopDefault(import('../pages/search-results/index.vue' /* webpackChunkName: "pages/search-results/index" */))
const _6a3196c1 = () => interopDefault(import('../pages/set-password.vue' /* webpackChunkName: "pages/set-password" */))
const _fd349384 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _96361db4 = () => interopDefault(import('../pages/signout.vue' /* webpackChunkName: "pages/signout" */))
const _8683facc = () => interopDefault(import('../pages/smart-login.vue' /* webpackChunkName: "pages/smart-login" */))
const _1778e309 = () => interopDefault(import('../pages/unsubscribe/index.vue' /* webpackChunkName: "pages/unsubscribe/index" */))
const _018ba780 = () => interopDefault(import('../pages/update-profile.vue' /* webpackChunkName: "pages/update-profile" */))
const _5367e55c = () => interopDefault(import('../pages/job-match-feedback/thankyou.vue' /* webpackChunkName: "pages/job-match-feedback/thankyou" */))
const _85e7c74c = () => interopDefault(import('../pages/notifications/saved-searches-freq/index.vue' /* webpackChunkName: "pages/notifications/saved-searches-freq/index" */))
const _09ead272 = () => interopDefault(import('../pages/notifications/subscriptions/index.vue' /* webpackChunkName: "pages/notifications/subscriptions/index" */))
const _e3066a7c = () => interopDefault(import('../pages/static/privacy.vue' /* webpackChunkName: "pages/static/privacy" */))
const _0272cc7e = () => interopDefault(import('../pages/static/terms.vue' /* webpackChunkName: "pages/static/terms" */))
const _4aee77e4 = () => interopDefault(import('../pages/unsubscribe/thankyou.vue' /* webpackChunkName: "pages/unsubscribe/thankyou" */))
const _8e658c9a = () => interopDefault(import('../pages/job/expired/_id.vue' /* webpackChunkName: "pages/job/expired/_id" */))
const _410f9f60 = () => interopDefault(import('../pages/browse/_slug.vue' /* webpackChunkName: "pages/browse/_slug" */))
const _9cd09d82 = () => interopDefault(import('../pages/discover/_slug.vue' /* webpackChunkName: "pages/discover/_slug" */))
const _70526798 = () => interopDefault(import('../pages/job-preview/_id.vue' /* webpackChunkName: "pages/job-preview/_id" */))
const _74926a32 = () => interopDefault(import('../pages/job-redirect/_id.vue' /* webpackChunkName: "pages/job-redirect/_id" */))
const _a84fde86 = () => interopDefault(import('../pages/jobview/_slug/_id.vue' /* webpackChunkName: "pages/jobview/_slug/_id" */))
const _acbf2bb2 = () => interopDefault(import('../pages/discover/_slug' /* webpackChunkName: "" */))
const _3acb9b86 = () => interopDefault(import('../pages/company-article/index' /* webpackChunkName: "" */))
const _3a8685ac = () => interopDefault(import('../pages/static/privacy' /* webpackChunkName: "" */))
const _740be3a9 = () => interopDefault(import('../pages/static/terms' /* webpackChunkName: "" */))
const _79f68e3c = () => interopDefault(import('../pages/home' /* webpackChunkName: "" */))
const _0e9ce067 = () => interopDefault(import('../pages/company-search-results/index' /* webpackChunkName: "" */))
const _abaa2122 = () => interopDefault(import('../pages/discover/index' /* webpackChunkName: "" */))
const _26ed34eb = () => interopDefault(import('../pages/search' /* webpackChunkName: "" */))
const _46680817 = () => interopDefault(import('../pages/search-results/index' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/applypass",
    component: _3ad36a77,
    name: "applypass"
  }, {
    path: "/auth",
    component: _40c986c3,
    name: "auth"
  }, {
    path: "/banjo",
    component: _188bd009,
    name: "banjo"
  }, {
    path: "/banjo-home",
    component: _19044453,
    name: "banjo-home"
  }, {
    path: "/browser-update",
    component: _91b0cd2e,
    name: "browser-update"
  }, {
    path: "/company-article",
    component: _6cfd1b1e,
    name: "company-article"
  }, {
    path: "/company-search-results",
    component: _8a21f102,
    name: "company-search-results"
  }, {
    path: "/discover",
    component: _99635af2,
    name: "discover"
  }, {
    path: "/error",
    component: _d0cef306,
    name: "error"
  }, {
    path: "/forgot-password",
    component: _6740afba,
    name: "forgot-password"
  }, {
    path: "/home",
    component: _631fab0c,
    name: "home"
  }, {
    path: "/job-match-feedback",
    component: _97b0170a,
    name: "job-match-feedback"
  }, {
    path: "/jobs",
    component: _456ea798,
    name: "jobs"
  }, {
    path: "/maintenance",
    component: _9b42df70,
    name: "maintenance"
  }, {
    path: "/my-searches",
    component: _4b97080f,
    name: "my-searches"
  }, {
    path: "/not-found",
    component: _463d0280,
    name: "not-found"
  }, {
    path: "/one-auth",
    component: _783734ca,
    name: "one-auth"
  }, {
    path: "/profile",
    component: _bb5cfb44,
    name: "profile"
  }, {
    path: "/reset-email",
    component: _cbfb355a,
    name: "reset-email"
  }, {
    path: "/reset-password",
    component: _6bd50a34,
    name: "reset-password"
  }, {
    path: "/resume-writing",
    component: _d2b442d2,
    name: "resume-writing"
  }, {
    path: "/search",
    component: _5b8d0bfa,
    name: "search"
  }, {
    path: "/search-results",
    component: _447ed72f,
    name: "search-results"
  }, {
    path: "/set-password",
    component: _6a3196c1,
    name: "set-password"
  }, {
    path: "/settings",
    component: _fd349384,
    name: "settings"
  }, {
    path: "/signout",
    component: _96361db4,
    name: "signout"
  }, {
    path: "/smart-login",
    component: _8683facc,
    name: "smart-login"
  }, {
    path: "/unsubscribe",
    component: _1778e309,
    name: "unsubscribe"
  }, {
    path: "/update-profile",
    component: _018ba780,
    name: "update-profile"
  }, {
    path: "/job-match-feedback/thankyou",
    component: _5367e55c,
    name: "job-match-feedback-thankyou"
  }, {
    path: "/notifications/saved-searches-freq",
    component: _85e7c74c,
    name: "notifications-saved-searches-freq"
  }, {
    path: "/notifications/subscriptions",
    component: _09ead272,
    name: "notifications-subscriptions"
  }, {
    path: "/static/privacy",
    component: _e3066a7c,
    name: "static-privacy"
  }, {
    path: "/static/terms",
    component: _0272cc7e,
    name: "static-terms"
  }, {
    path: "/unsubscribe/thankyou",
    component: _4aee77e4,
    name: "unsubscribe-thankyou"
  }, {
    path: "/job/expired/:id?",
    component: _8e658c9a,
    name: "job-expired-id"
  }, {
    path: "/browse/:slug?",
    component: _410f9f60,
    name: "browse-slug"
  }, {
    path: "/discover/:slug",
    component: _9cd09d82,
    name: "discover-slug"
  }, {
    path: "/job-preview/:id?",
    component: _70526798,
    name: "job-preview-id"
  }, {
    path: "/job-redirect/:id?",
    component: _74926a32,
    name: "job-redirect-id"
  }, {
    path: "/jobview/:slug?/:id?",
    component: _a84fde86,
    name: "jobview-slug-id"
  }, {
    path: "/my-activity/:slug",
    component: _acbf2bb2,
    name: "my-activity-slug"
  }, {
    path: "/my-searches/:slug",
    component: _acbf2bb2,
    name: "my-searches-slug"
  }, {
    path: "/jobs/:filters?/article/:article",
    component: _3acb9b86,
    name: "company-article"
  }, {
    path: "/privacy",
    component: _3a8685ac
  }, {
    path: "/terms",
    component: _740be3a9
  }, {
    path: "/",
    component: _79f68e3c,
    name: "home"
  }, {
    path: "/*/:brand(.*br_.*)",
    component: _0e9ce067,
    name: "company-search-results"
  }, {
    path: "/discover/",
    component: _abaa2122,
    name: "discover"
  }, {
    path: "/search/",
    component: _26ed34eb,
    name: "search"
  }, {
    path: "/*",
    component: _46680817,
    name: "default-search-results"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
